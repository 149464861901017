<template>
    <form novalidate=true @submit.prevent="enviarFormularioFromForm">
        <div class="row mb-3" v-if="!isModal">
            <div class="col-6">
                <h2 class="text-dark">
                    {{ titulo }}
                </h2>
            </div>
		</div>
        <div class="row">
            <div class="row col-md-12">
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="name" class="form_label ">Nombre</label>
                        <input class="form-control" v-model="form.name" :class="{'is-invalid': validaciones.name.error}" type="text" id="name" maxlength="255">
                    </div>
                </div>
                <div class="col-md-4 row">
                    <div class="col-8 pr-1">
                        <div class="form-group">
                            <label for="quentity" class="form_label ">Cantidad</label>
                            <input class="form-control" v-model="form.quantity" :class="{'is-invalid': validaciones.quantity.error}" type="number" id="quantity"/>
                        </div>
                    </div>
                    <div class="col-4 p-0">
                        <label class="form_label w-100">&nbsp;</label>
                        <a class="btn btn-sm btn-danger text-white mr-1" title="Quitar" @click="minus" :disabled="disabledMinus" :class="{ 'disabled': disabledMinus }">
                            <font-awesome-icon icon="minus" />
                        </a>
                        <a class="btn btn-sm btn-success text-white" title="Agregar" @click="plus">
                            <font-awesome-icon icon="plus" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="row col-md-12">
                <div class="col-md-12 ">
                    <div class="form-group">
                        <label for="link" class="form_label ">Link</label>
                        <input class="form-control" v-model="form.link" type="text" id="name" maxlength="255">
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row mb-3" v-if="!isModal">
            <div class="col-12">
                <hr />
            </div>
            <div class="col-6 text-left">
                <button type="button" class="btn btn-outline-dark" title="Volver" @click="volver">
                    <font-awesome-icon icon="arrow-left" /> Volver
                </button>
            </div>
            <div class="col-6 text-right">
                <button type="submit" class="btn btn-success" title="Guardar" v-loading-btn="{loading: loading}" >
                    <font-awesome-icon icon="save" /> Guardar
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faSave, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Validaciones } from '../../utils/funciones';
import _ from "lodash";

library.add([faArrowLeft, faSave, faPlus, faMinus]);

export default {
    name: 'ListaCompraDetalleForm',
    components: {
    },
    props: {
        save: {
            type: Boolean,
            default: false
        },
        isModal: {
            type: Boolean,
            default: false
        },
        idToEdit: {
            type: String,
            default: null
        }
    },
    watch: {
        save: function (value) {
            let self = this;
            if(!self.savingFromForm && value) {
                this.enviarFormulario();
            }
        },
        idToEdit: {
            handler: function(id) {
                let self = this;
                if(id) {
                    self.estaEditando = true;
                    self.titulo = 'Editar ítem';
                    self.buscar(id);
                }
            },
            // deep: true,
            immediate: true
        }
    },
    data: () => {
        return {
            titulo: 'Crear ítem detalle',
            loading: false,
            estaEditando: false,
            savingFromForm: false,
            disabledMinus: false,
            form: {
                id: null,
                name: null,
                quantity: 1,
                link: null
            },
            validaciones: {
                name: {
                    error: false,
                    reglas: 'not-empty'
                },
                quantity: {
                    error: false,
                    reglas: 'not-empty'
                }
            }
        }
    },
    methods: {
        plus() {
            this.form.quantity++;
            if(this.form.quantity > 0) {
                this.disabledMinus = false;
            }
        },
        minus() {
            this.form.quantity--;
            if(this.form.quantity <= 0) {
                this.disabledMinus = true;
            }
        },
        enviarFormularioFromForm() {
            this.savingFromForm = true;
            this.enviarFormulario();
        },
        buscar(id) {
		    let self = this;
            self.estaEditando = true;
            self.titulo = 'Editar etiquetas';

            self.axios.all([
                self.axios.get(`shopping-cart-details/${id}`)
            ])
            .then(self.axios.spread(
                (data) => {
                    self.form.id = data.data.id;
                    self.form.name = data.data.name;
                    self.form.quantity = data.data.quantity;
                    self.form.link = data.data.link;
                }
            )).catch((err) => {
                self.$toastr.e(err, "Exception");
            });
        },
        enviarFormulario() {
            let self = this;

            self.loading = true;
            const validacionesFinal = {...self.validaciones};

            Validaciones.reset(validacionesFinal);
            Validaciones.validar(self.form, validacionesFinal);
            let tieneError = Validaciones.hasError(validacionesFinal);

            if(!tieneError) {
                self.$emit('update:save', true);
                const data = {...self.form };

                let $promise = null;
                delete data.id;
                if(self.estaEditando || self.form.id) {
                    $promise = self.axios.put(`shopping-cart-details/${self.form.id}`, data);
                } else {
                    $promise =  self.axios.post(`shopping-cart-details/true`, data);
                }
                
                $promise
                .then(function(response) {
                    self.$emit('update:save', false);
                    if(response.error) {
                        self.loading = false;
                        self.$toastr.e(response.message, "Error al guardar ítem");
                    } else {
                        self.loading = false;
                        self.$toastr.s("Ítem guardado exitosamente", "Correcto");
                        self.$emit('entity', response.data);

                        if(!self.isModal) {
                            self.volver();
                        }
                    }
                })
                .catch(function (error) {
                    self.loading = false;
                    self.$emit('update:save', false);
                    self.$toastr.e(error, "Exception");
                });
            } else {
                self.loading = false;
                self.$toastr.e("El formulario tiene errores, verifique", "Error");
                self.$emit('update:save', false);
            }
        }
    },
    beforeMount: function(){
		let self = this;

        if(self.$route.params.id) {
            self.estaEditando = true;
            self.titulo = 'Editar ítem';

            self.buscar(self.$route.params.id);
        }
    }
}
</script>

<style>
</style>