<template>
    <div>
        <div class="row mb-3">
            <div class="col-6">
                <h2 class="text-dark">
                    Lista de compras
                </h2>
            </div>
            <div class="col-6 text-right">
                <!-- <button class="btn btn-info mr-1" v-if="dataLoaded" @click="add()">
                    <font-awesome-icon icon="list" /> Anteriores
                </button>
                <button class="btn btn-warning mr-1" v-if="dataLoaded && hasActive" @click="add()">
                    <font-awesome-icon icon="plus" /> Finalizar
                </button>
                <button class="btn btn-success mr-1" v-if="dataLoaded && !hasActive" @click="add()">
                    <font-awesome-icon icon="plus" /> Crear
                </button> -->
                <button class="btn btn-success mr-1" v-if="dataLoaded && hasActive" @click="addDetail()">
                    <font-awesome-icon icon="plus" /> Agregar detalle
                </button>
            </div>
            <div class="col-12" v-if="hasActive">
                <div class="alert alert-info" role="alert">
                    <div class="row">
                        <div class="col-md-3 col-6">
                            Nombre: <b>{{dataActive.name}}</b>
                        </div>
                        <div class="col-md-3 col-6">
                            Fecha: <b>{{ moment(dataActive.date).format('DD-MM-YYYY') }}</b>
                        </div>
                    </div>
                </div>
            </div>
		</div>
        <div class="table-responsive">
            <vuetable ref="vuetable"
                :fields="fields"
                :apiUrl="vueTableConfig.url"
                :per-page="vueTableConfig.perPage"
                :appendParams="vueTableConfig.appendParams"
                :css="vueTableConfig.css.table"
                @vuetable:pagination-data="onPaginationData"
                pagination-path=""
                :transform="transform"
                @vuetable:loaded="vuetableLoaded"
                @vuetable:loading="vuetableLoading"
            >
                <div slot="name-slot" slot-scope="item">
                    <div class="row">
                        <div class="col-12">
                            <span>{{item.rowData.name}}</span>
                            <!-- <span v-if="item.rowData.shoppingCartDetailsStatus.slug == 'pendiente'" class="badge badge-danger">
                                Pendiente
                            </span>
                            <span v-if="item.rowData.shoppingCartDetailsStatus.slug == 'comprado'" class="badge badge-success">
                                Comprado
                            </span>
                            <span v-if="item.rowData.shoppingCartDetailsStatus.slug == 'pospuesto'" class="badge badge-secondary">
                                Pospuesto
                            </span> -->
                        </div>
                    </div>
                </div>
                <div slot="cantidad-slot" slot-scope="item">
                    <div class="row">
                        <div class="col-3 col-md-2">
                            <span>{{item.rowData.quantity}}</span>
                        </div>
                    </div>
                </div>
                <div slot="comprado-slot" slot-scope="item">
                    <div class="row">
                        <div class="col-3 col-md-2" :set="active = switchActivo(item.rowData)">
                            <toggle-button 
                                :value=active
                                :labels="{checked: 'Si', unchecked: 'No'}" 
                                :color="{checked: '#2eb85c', unchecked: '#e55353'}" 
                                :sync="true"
                                @change="changeItemStatus(item.rowData, false)"
                                />
                        </div>
                    </div>
                </div>
                <div slot="acciones-slot" slot-scope="item">
                    <b-dropdown id="dropdown-1" text="Acciones" variant="primary">
                        <a class="dropdown-item" href="#" @click.prevent="changeItemStatus(item.rowData, true)">
                            <font-awesome-icon icon="clock" />&nbsp;Posponer
                        </a>
                        <a class="dropdown-item" href="#" @click.prevent="edit(item.rowData)">
                            <font-awesome-icon icon="edit" />&nbsp;Editar
                        </a>
                        <b-dropdown-divider></b-dropdown-divider>
                            <a class="dropdown-item" @click="remove(item.rowData)">
                                <font-awesome-icon icon="trash-alt" />&nbsp;Eliminar
                            </a>
                    </b-dropdown>
                </div>
            </vuetable>
		</div>
        <div class="pt-1 pb-3 float-right">
            <vuetable-pagination ref="pagination"
                :css="vueTableConfig.css.pagination"
                @vuetable-pagination:change-page="onChangePage"
            />
            <vuetable-pagination-info ref="paginationInfo" 
                :css="vueTableConfig.info.css"
                :infoTemplate="vueTableConfig.info.infoTemplate"
                :noDataTemplate="vueTableConfig.info.noDataTemplate"
            />
        </div>
        <confirm ref="confirm"></confirm>
        <ModalListaCompraDetalle :show.sync="modalDetalle" @closed="closeModalDetalle" @entity="itemCreated" :id-to-edit-modal="idToEdit"/>
    </div>
</template>
<script>
    import vueTableMixin from "@/mixins/VueTableMixin";

    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faPlus, faEdit, faTrashAlt, faUserCog, faBook, faList, faClock} from '@fortawesome/free-solid-svg-icons';

    // import _ from "lodash";

    import Confirm from '../../components/vue-confirm/Confirm.vue';
    import ModalListaCompraDetalle from '../listaComprasDetalles/ModalListaCompraDetalle.vue';

    library.add([faPlus, faEdit, faTrashAlt, faUserCog, faBook, faList, faClock]);

    export default {
        name: 'Etiquetas',
        mixins: [
            vueTableMixin
        ],
        components: {
            Confirm,
            ModalListaCompraDetalle
        },
        data: () => {
            return {
                modal: false,
                modalDetalle: false,
                idToEdit: null,
                dataLoaded: false,
                hasActive: false,
                dataActive: {},
                fields: [
                    {
                        title: 'Nombre',
                        name: "name-slot",
                        sortField: 'name'
                    },
                    {
                        title: 'Cantidad',
                        name: "cantidad-slot",
                        sortField: 'quantity'
                    },
                    {
                        title: 'Comprado',
                        name: "comprado-slot",
                        sortField: 'shopping_cart_details_status_id'
                    },
                    {
                        title: '',
                        name: "acciones-slot"
                    }
                ]
            }
        },
        // watch: {
        //     hasActive: function(valor) {
        //         console.log("ocultar o mostrar");
        //         if(valor) {
        //         }
        //     }
        // },
        methods: {
            add() {
                this.modal = true;
            },
            addDetail() {
                this.modalDetalle = true;
            },
            edit(item) {
                this.modalDetalle = true;
                this.idToEdit = item.id
            },
            switchActivo(item) {
                if(item.shoppingCartDetailsStatus.slug == 'pendiente') {
                    return false;
                } else {
                    return true;
                }
            },
            closeModal() {
                this.modal = false;
                this.idToEdit = null;
            },
            closeModalDetalle() {
                this.modalDetalle = false;
            },
            vuetableLoaded() {
                let self = this;
                console.log('vuetableLoaded');

                console.log("data => ", self.$refs.vuetable.countTableData);
            },
            vuetableLoading() {
                console.log('vuetableLoading');
            },
            changeItemStatus(item, isPospuesto = false) {
                let self = this;
                let data = {};

                if(isPospuesto) {
                    data.status_slug = 'pospuesto';
                } else {
                    if(item.shoppingCartDetailsStatus.slug == 'pendiente') {
                        data.status_slug = 'comprado';
                    } else if(item.shoppingCartDetailsStatus.slug == 'comprado') {
                        data.status_slug = 'pendiente';
                    }
                }

                self.axios
                .put(`shopping-cart-details/${item.id}`, data)
                .then(function(response) {
                    if(response.error) {
                        self.$toastr.e(response.message, "Error al actualizar ítem");
                    } else {
                        self.refresh();
                    }
                })
                .catch(function (error) {
                    self.$toastr.e(error, "Exception");
                });
            },
            itemCreated() {
                let self = this;
                self.refresh();
            },
            remove ( item ) {
                let self = this;

                self.$refs.confirm.show({
                    html: true,
                    message: `¿ Está seguro de eliminar el ítem <b>${item.name}</b> del carrito ?`,
                    okButton: 'Eliminar',
                    callback: confirm => {
                        if (confirm) {
                            self.$refs.confirm.setLoading(true);
                            self.axios
                            .delete(`shopping-cart-details/${item.id}`)
                            .then(response => {
                                self.$refs.confirm.setLoading(false);
                                if(response.error) {
                                    self.$toastr.e(response.message, "Error");
                                } else {
                                    self.$toastr.s(response.message, "Correcto");
                                    self.$refs.confirm.close();
                                    self.refresh();
                                }
                            })
                            .catch(function (error) {
                                self.$refs.confirm.setLoading(false);
                                self.$toastr.e(error, "Exception");
                            });
                        }
                    }
                });
            }
        },
        beforeMount: function() {
            let self = this;


            self.axios.get(`shopping-cart-has-active`)
            .then(function(response) {
                const tieneListaActiva = response.data.active;
                self.dataActive = response.data.entity;

                self.dataLoaded = true;
                if(tieneListaActiva) {
                    self.hasActive = true;
                    self.vueTableConfig.url = `${self.$apiAdress}shopping-cart-details-active`;
                } else {
                    self.hasActive = false;
                }
            })
            .catch(function (error) {
                self.$toastr.e(error, "Exception");
            });
        }
    }
</script>
<style scoped>
    .spanColor {
        content: "";
        border-radius: 6px;
        height: 20px;
        width: 60px;
        display: inline-block;
    }

    .spanPendiente {
        background-color: #ff0000;
    }

    .spanComprado {
        background-color: #00ff00;
    }

    .spanPospuesto {
        background-color: #0000ff;
    }
</style>